import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import markAsDoneReducer from "./markAsDoneReducer";
import cancelOrderReducer from "./cancelOrderReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import productReducer from "./productReducer";
import expenseReducer from "./expenseReducer";
import updatePaymentInfoReducer from "./updatePaymentInfoReducer";

const rootReducer = combineReducers({
  auth: authReducer, //updates info on the auth property
  order: orderReducer, //updates info on the order property
  product: productReducer, //updates info on the product property
  markAsDone: markAsDoneReducer,
  expense: expenseReducer,
  cancelOrder: cancelOrderReducer,
  updatePaymentInfoReducer: updatePaymentInfoReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
