import React from "react";
import { withStyles, Grid, TextField } from "@material-ui/core";
import { createOrder } from "../../store/actions/projectActions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { updateProductCount } from "../../store/actions/productActions";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    margin: "auto"
  },

  form: {
    backgroundColor: "white",
    width: "100%",
    margin: "auto",
    padding: "10px",

    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  formControl: {
    width: "100%",
    marginBottom: 20
  },

  padding: {
    padding: theme.spacing(1)
  },

  label: {
    fontSize: "15px",
    marginTop: "10px",
    display: "inline-block"
  },
  button: {
    padding: "10px"
  }
});

class Checkout extends React.Component {
  state = {
    email: "",
    items: {},
    totalPaid: 0,
    courierCompany: "",
    trackingNumber: null,
    courierFee: 0,
    discount: 0
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });

    if (e.target.id === "courierCompany") {
      this.props.calcCourierFee(e.target.value);
    }

    if (e.target.id === "discount") {
      this.props.getDiscount(e.target.value); // this is actually setting the discount --refacto
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    let orderForm = this.props.cartItems;
    let checkoutBasket = [];
    let updatedInventory = [];

    orderForm.forEach(item => {
      let count = item.count;
      updatedInventory.push({ name: item.name, count: item.count });
     // for (var i = 0; i < count; i++) {
        checkoutBasket.push({ name: item.name, price: item.price , count: item.count });
     // }
    });

    let cost =
      this.props.totalCost +
      this.props.courierFee -
      Number(this.state.discount);
    let payment = Number(this.state.totalPaid);

    if (cost <= 0) {
      alert("Cost has to be above R0.");
    } else if (payment > cost) {
      alert("Payment can not be greater than the total owing.");
    } else {
      this.setState(
        {
          items: checkoutBasket,
          totalCost: cost,
          courierFee: this.props.courierFee,
          discount: this.state.discount
        },
        () => {
          this.props.createOrder(this.state);
          this.props.updateProductCount(updatedInventory);
        }
      );
    }
  };

  render() {
    const { classes, auth, users } = this.props;
    if (users) {
    } else {
      console.log("no users");
    }
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div className={classes.root}>
        <form
          id="addOrderForm"
          className={classes.form}
          onSubmit={this.handleSubmit}
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              inputProps={{
                id: "courierCompany"
              }}
              value={this.state.courierCompany}
              className={classes.text}
              native
              required
              onChange={this.handleChange}
            >
              <option value="" disabled selected>
                Select Courier
              </option>
              <option key="fastway">fastway</option>
              <option key="postnet">postnet</option>
              <option key="none">None</option>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            id="trackingNumber"
            label="Tracking Number"
            type="trackingNumber"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            id="discount"
            label="Discount"
            type="discount"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
          />

          <FormControl variant="outlined" className={classes.formControl}>
            {users ? (
              <Select
                value={this.state.email}
                className={classes.text}
                native
                required
                onChange={this.handleChange}
                inputProps={{
                  id: "email"
                }}
              >
                <option value="" disabled selected>
                  Select Customer
                </option>
                {users.map(user => (
                  <option key={user.id}>{user.email}</option>
                ))}
              </Select>
            ) : null}
          </FormControl>

          <TextField
            variant="outlined"
            id="totalPaid"
            label="Total Paid"
            type="totalPaid"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
          />

          <Grid container justify="center" className={classes.button}>
            <Tooltip title="Add" aria-label="add" type="submit">
              <Fab color="primary" className={classes.fab}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    users: state.firestore.ordered.users,
    inventory: state.firestore.ordered.inventory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrder: order => dispatch(createOrder(order)),
    updateProductCount: products => dispatch(updateProductCount(products))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    { collection: "users", orderBy: ["email"] },
    { collection: "inventory" },
    { collection: "payments" }
  ]),
  withStyles(styles)
)(Checkout);
