import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles({
  addExpense: {
    padding: 15,
    marginTop: 20
  },
  formControl: {
    marginTop: 20
  }
});

const FilterExpenses = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.addExpense}>
      <h3>Filter</h3>
      <TextField
        id="nameFilter"
        label="Filter by Name"
        fullWidth
        autoFocus
        required
        onChange={props.handleChange}
      />

      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          id="yearFilter"
          className={classes.text}
          native
          required
          onChange={props.handleChange}
        >
          <option value="" disabled selected>
            Year
          </option>
          <option value="2021" key="2021">
            2021
          </option>
          <option value="2022" key="2022">
            2022
          </option>
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          id="dateFilter"
          className={classes.text}
          native
          required
          onChange={props.handleChange}
        >
          <option value="" disabled selected>
            Month
          </option>
          <option value="1" key="1">
            January
          </option>
          <option value="2" key="2">
            February
          </option>
          <option value="3" key="3">
            March
          </option>
          <option value="4" key="4">
            April
          </option>
          <option value="5" key="5">
            May
          </option>
          <option value="6" key="6">
            June
          </option>
          <option value="7" key="7">
            July
          </option>
          <option value="8" key="8">
            August
          </option>
          <option value="9" key="9">
            September
          </option>
          <option value="10" key="10">
            October
          </option>
          <option value="11" key="11">
            November
          </option>
          <option value="12" key="12">
            December
          </option>
        </Select>
      </FormControl>
    </Paper>
  );
};

export default FilterExpenses;
