import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    },
    height: "66px",
    position: "fixed",
    bottom: 0,
    zIndex: 5000,
    backgroundColor: "#3498db",
    width: "100%",
    color: "white"
  },
  icon: {
    color: "white",
    marginTop: "5px"
  },
  icon2: {
    color: "white"
  },
  counter: {
    backgroundColor: "red",
    borderRadius: "20px",
    width: "20px",
    height: "20px",
    position: "absolute",
    top: "15px",
    left: "90px"
  },
  counterText: {
    textAlign: "center"
  }
}));

export default function InventoryButtons(props) {
  const classes = useStyles();
  var showCounter = false;
  var counterValue = 0;
  if (props.cartValue.length > 0) {
    showCounter = true;
  }

  props.cartValue.forEach(element => {
    counterValue = counterValue + element.count;
  });

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="create"
        onClick={props.showCart}
        to="/CreateProduct"
      >
        <Link to="/CreateProduct">
          <AddToQueueIcon className={classes.icon} />
        </Link>
      </IconButton>

      <IconButton aria-label="show" onClick={props.showCart}>
        <ShoppingCartIcon className={classes.icon2} />
      </IconButton>

      {showCounter ? (
        <div id="counter" className={classes.counter}>
          <div className={classes.counterText}>{counterValue}</div>
        </div>
      ) : null}
    </div>
  );
}
