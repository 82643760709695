import React from "react";
import { Paper, withStyles, Grid, TextField, Button } from "@material-ui/core";
import { updatePaymentInfo } from "../../store/actions/updatePaymentInfoActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto",
      marginTop: theme.spacing(15)
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "auto",
      marginTop: theme.spacing(15)
    }
  },
  margin: {
    margin: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(1)
  }
});

class UpdatePaymentDetails extends React.Component {
  state = {
    id: "",
    payment: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updatePaymentInfo(this.state);
    this.props.history.push("/");
  };

  render() {
    const { classes, auth, id } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;
    if (this.state.id === "") {
      this.setState({ id: id });
    }

    return (
      <div className={classes.root}>
        <h3>Update Payment Information</h3>

        <Paper className={classes.padding}>
          <div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="payment"
                  label="Payment"
                  type="payment"
                  fullWidth
                  autoFocus
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Button
                variant="outlined"
                color="primary"
                background-color="primary"
                style={{ textTransform: "none" }}
                onClick={e => this.handleSubmit(e)}
              >
                Update
              </Button>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    id: ownProps.match.params.id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePaymentInfo: trackingDetails =>
      dispatch(updatePaymentInfo(trackingDetails))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdatePaymentDetails));
