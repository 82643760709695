import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
    margin: "0 auto"
  },
  cell: {
    padding: 2,
    fontSize: 9
  }
}));

export default function AccountsTableMobile({ filteredPayments }) {
  const classes = useStyles();

  let rows = [];
  if (filteredPayments) {
    rows = filteredPayments.map(data => {
      return {
        id: data.id,
        email: data.email,
        date: new Date(data.date).toDateString(),
        payment: parseInt(data.payment)
      };
    });

    rows = rows.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  }
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>Email</TableCell>
            <TableCell className={classes.cell}>Payment</TableCell>
            <TableCell className={classes.cell}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell className={classes.cell}>{row.email}</TableCell>
              <TableCell className={classes.cell}>{row.date}</TableCell>
              <TableCell className={classes.cell}>{row.payment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
