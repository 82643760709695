import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    marginBottom: 5
  },
  name: {
    color: "#3b5998",
    fontSize: 14,
    fontWeight: 800
  },
  email: {
    fontSize: 12,
    fontWeight: 800
  }
}));

const UserSummary = ({ user }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <CardContent>
          {user.name ? (
            <Typography
              className={classes.name}
              color="textPrimary"
              gutterBottom
            >
              {user.name}
            </Typography>
          ) : null}
          <Typography
            className={classes.email}
            color="textPrimary"
            gutterBottom
          >
            {user.email}
          </Typography>

          <Typography
            className={classes.address}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {user.address} , {user.suburb} , {user.city}
          </Typography>

          <Typography
            className={classes.code}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {user.code}
          </Typography>

          <Typography
            className={classes.phone}
            variant="body2"
            color="textSecondary"
          >
            {user.phone}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserSummary;
