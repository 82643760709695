import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  orderDetails: {
    marginTop: "80px",
    width: "100%"
  },
  root: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "auto",
      marginTop: "10px"
    }
  },
  cancelled: {
    color: "red"
  },
  card: {
    marginBottom: "2px",
    padding: "10px"
  },
  name: {
    fontWeight: "bold"
  },
  orderHistory: {
    marginBottom: "10px"
  },
  trackingDetails: {
    height: "100vh",
    width: "100%",
    backgroundColor: "red"
  }
}));

function OrderDetails(props) {
  const classes = useStyles();
  const { order, auth, userDetails, userOrders } = props;

  let courierService = "";
  let trackingNumber = "";
  let orderList = "";

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  if (!auth.uid) return <Redirect to="/signin" />;

  if (order) {
    const items = order.items;
    const itemNameString = "",
      itemNamesString = items.map(item => itemNameString + " ," + item.name);

    let itemNames = itemNamesString.toString();
    itemNames = itemNames.substring(2);

    let date = new Date(order.history[0].date).toString();

    courierService = order.courierCompany || "";
    trackingNumber = order.trackingNumber || "";

    if (userOrders.length > 0) {
      orderList = userOrders.map(userOrder => {
        return (
          <div className={classes.orderHistory} key={userOrder.id}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.name}
            >
              {userOrder.item}
            </Typography>

            {order.totalOwing ? (
              <Typography variant="body2" color="textSecondary" component="p">
                Amount Outstanding: R{userOrder.totalOwing}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                Amount Outstanding: R{userOrder.totalCost - userOrder.totalPaid}
              </Typography>
            )}

            {!order.completed ? (
              <Typography variant="body2" color="textSecondary" component="p">
                Completed
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                In Progress
              </Typography>
            )}
          </div>
        );
      });
    }

    return (
      <div className={classes.orderDetails}>
        <Card className={classes.root}>
          <CardContent>
            <h3>Delivery Information</h3>

            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.name ? userDetails.name : null}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.email ? userDetails.email : null}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.address}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.suburb}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.city}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.code}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {userDetails.phone}
            </Typography>
          </CardContent>
        </Card>

        <Card className={classes.root}>
          <CardContent>
            <h3>Order Information</h3>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.name}
            >
              {order.name}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              {itemNames}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              Total Cost: R{order.totalCost}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              Amount Paid: R{order.totalPaid}
            </Typography>

            {order.totalOwing ? (
              <Typography variant="body2" color="textSecondary" component="p">
                Amount Outstanding: R{order.totalOwing}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                Amount Outstanding: R{order.totalCost - order.totalPaid}
              </Typography>
            )}

            <Typography variant="body2" color="textSecondary" component="p">
              Courier Service : {courierService}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              Tracking Number : {trackingNumber}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              Created By: {order.createdBy}
            </Typography>

            <Typography variant="body2" color="textSecondary" component="p">
              Date Created: {date}
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <CardContent>
            <h3>Order History</h3>
            {orderList}
          </CardContent>
        </Card>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const orders = state.firestore.data.orders;
  const orderedList = state.firestore.ordered.orders;
  const users = state.firestore.ordered.users;
  const order = orders ? orders[id] : null;

  let userDetails = {};
  let userOrders = [];

  if (order) {
    if (users) {
      users.forEach(user => {
        if (user.email === order.email) {
          userDetails = user;
        }
      });
    }

    if (orderedList) {
      orderedList.forEach(item => {
        if (item.email === order.email) {
          userOrders.push(item);
        }
      });
    }
  }

  return {
    order: order,
    auth: state.firebase.auth,
    userDetails: userDetails,
    userOrders: userOrders
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "orders" }, { collection: "users" }])
)(OrderDetails);
