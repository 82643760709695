import React from "react";
import { Paper, withStyles, Grid, TextField } from "@material-ui/core";
import { updateProduct } from "../../store/actions/productActions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    margin: "auto",
    padding: 20,
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginTop: theme.spacing(15)
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      marginTop: theme.spacing(10)
    }
  },
  form: {
    backgroundColor: "white",
    width: "80%",
    margin: "auto",
    padding: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },

  text: {
    paddingBottom: 20
  },

  desc: {
    height: 60,
    paddingBottom: 20
  },

  heading: {
    color: "#3b5998",
    marginLeft: 10,
    fontSize: 20,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
      paddingTop: 10,
      paddingBottom: 10
    }
  }
});

class UpdateProduct extends React.Component {
  state = {
    id: this.props.itemDetails.id,
    name: this.props.itemDetails.name,
    description: this.props.itemDetails.description,
    price: this.props.itemDetails.price,
    amountAvailable: this.props.itemDetails.amountAvailable,
    amountAwaiting: this.props.itemDetails.amountAwaiting,
    tags: this.props.itemDetails.tags,
    isVisible: this.props.itemDetails.isVisible,
    sale: this.props.itemDetails.sale,
    salePrice: this.props.itemDetails.salePrice
  };

  handleChange = e => {
    if (e.target.id) {
      let value = e.target.value;

      if (
        e.target.id === "price" ||
        e.target.id === "amountAvailable" ||
        e.target.id === "amountAwaiting"
      ) {
        value = Number(e.target.value);
      }

      this.setState({
        [e.target.id]: value
      });
    }
  };

  toggleVisible = e => {
    let isVisible = !this.state.isVisible;
    this.setState({
      [e.target.id]: isVisible
    });
  };

  toggleSale = e => {
    let sale = !this.state.sale;
    this.setState({
      [e.target.id]: sale
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateProduct(this.state);
    this.props.history.push("/inventory");
  };

  render() {
    const { classes, auth, itemDetails } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <Paper className={classes.root}>
        <Typography className={classes.heading}>Update Product</Typography>

        <form
          id="addOrderForm"
          className={classes.form}
          onSubmit={this.handleSubmit}
        >
          Visible on Site
          <Switch
            checked={this.state.isVisible}
            onClick={this.toggleVisible}
            id="isVisible"
          />
          On Sale
          <Switch
            checked={this.state.sale}
            onClick={this.toggleSale}
            id="sale"
          />
          <TextField
            id="description"
            variant="outlined"
            type="description"
            fullWidth
            autoFocus
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Description: " + itemDetails.description}
            InputProps={{ classes: { input: classes.desc } }}
          />
          <TextField
            id="price"
            variant="outlined"
            type="price"
            autoFocus
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Price: " + itemDetails.price}
          />
          <TextField
            id="salePrice"
            variant="outlined"
            type="salePrice"
            autoFocus
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Sale Price: " + itemDetails.salePrice}
          />
          <TextField
            variant="outlined"
            id="amountAvailable"
            type="amountAvailable"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Available: " + itemDetails.amountAvailable}
          />
          <TextField
            variant="outlined"
            id="amountAwaiting"
            type="amountAwaiting"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Awaiting: " + itemDetails.amountAwaiting}
          />
          <TextField
            variant="outlined"
            id="tags"
            type="tags"
            fullWidth
            className={classes.text}
            onChange={this.handleChange}
            placeholder={"Tags: " + itemDetails.tags}
          />
          <Grid container justify="center">
            <Tooltip title="Add" aria-label="add" type="submit">
              <Fab color="primary" className={classes.fab}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.history.location.query.docId;
  const inventory = state.firestore.ordered.inventory;
  let itemDetails = {};

  if (inventory) {
    inventory.map(item => {
      if (item.id === id) {
        itemDetails = item;
      } else {
      }
    });
  }

  return {
    auth: state.firebase.auth,
    itemDetails: itemDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProduct: product => dispatch(updateProduct(product))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    { collection: "users", orderBy: ["email"] },
    { collection: "inventory" }
  ]),
  withStyles(styles)
)(UpdateProduct);
