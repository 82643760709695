const initState = {
  admin: null,
  authError: null
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "AUTH_CHANGED": {
      return state;
    }

    case "LOGIN_ERROR": {
      return {
        ...state,
        authError: "Login Failed. Please ensure you have registered"
      };
    }
    case "LOGIN_SUCCESS": {
      return { ...state, authError: null };
    }
    case "LOGIN_FAILED_NOT_ADMIN": {
      return { ...state, authError: "Login Failed .Not Admin" };
    }
    case "LOGOUT_SUCCESS": {
      return state;
    }
    case "SIGNUP_ERROR": {
      console.log("err");
      return state;
    }
    case "SIGNUP_SUCCESS": {
      return state;
    }
    default:
      return state;
  }
};

export default authReducer;
