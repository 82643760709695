import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Loader from "../layout/Loader";
import { checkIfAdmin } from "../../store/actions/authActions";
import AccountsTable from "./AccountsTable";
import AccountsTableMobile from "./AccountsTableMobile";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles(theme => ({
  root: {
    color: "#3b5998",
    minHeight: "700px"
  },
  header: {
    color: "#3b5998",
    fontSize: "40px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px"
    }
  },
  table: {
    margin: "0 auto",
    padding: 5,
    [theme.breakpoints.down("xs")]: {
      padding: "2px",
      margin: "0"
    }
  },
  contentLoader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

const Accounts = props => {
  const classes = useStyles();
  const { auth, payments, checkIfAdmin } = props;
  const [filteredPayments, setFilteredPayments] = useState(null);

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 624px)"
  });

  useEffect(() => {
    setFilteredPayments(payments);
  }, [payments]);

  checkIfAdmin();
  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div className={classes.root}>
      <Typography className={classes.header}> Accounting </Typography>
      {payments ? (
        <div className={classes.table}>
          {isMobileDevice ? (
            <AccountsTableMobile filteredPayments={filteredPayments} />
          ) : (
            <AccountsTable filteredPayments={filteredPayments} />
          )}
        </div>
      ) : (
        <div className={classes.contentLoader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    payments: state.firestore.ordered.payments,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkIfAdmin: user => dispatch(checkIfAdmin(user))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "payments" }])
)(Accounts);
