const initState = {
  expense: []
};

const expenseReducer = (state = initState, action) => {
  switch (action.type) {
    case "ADD_EXPENSE": {
      console.log("added expense" + action.expense);
      return state;
    }
    case "ADD_EXPENSE_ERROR": {
      console.log("added expense ERROR" + action.ERROR);
      return state;
    }
    default:
      return state;
  }
};

export default expenseReducer;
