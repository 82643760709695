import { createMuiTheme } from "@material-ui/core/styles";
export function createTheme() {
  return createMuiTheme({
    palette: {
      primary: {
        main: "#3685B5"
      },
      secondary: {
        main: "#3498db"
      }
    }
  });
}
