import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles({
  expenseList: {
    marginTop: 50,
    marginBottom: 100
  },

  table: {
    minWidth: 300
  }
});

const ExpenseList = props => {
  const { expenses, auth, nameFilter, dateFilter, yearFilter } = props;
  const classes = useStyles();

  if (!auth.uid) return <Redirect to="/signin" />;

  let filteredExpenses = [];

  if (expenses) {
    filteredExpenses = expenses.filter(e => e.name.includes(nameFilter));

    filteredExpenses = filteredExpenses.filter(e =>
      new Date(e.date)
        .toLocaleDateString()
        .includes(dateFilter + "/" + yearFilter)
    );
  }

  return (
    <div className={classes.expenseList}>
      {filteredExpenses ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Amount&nbsp;(R)</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExpenses.map(e => (
                <TableRow key={e.name + e.amount + new Date(e.date)}>
                  <TableCell component="th" scope="expense">
                    {e.name}
                  </TableCell>
                  <TableCell align="right">{e.amount}</TableCell>
                  <TableCell align="right">
                    {new Date(e.date).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    expenses: state.firestore.ordered.expenses,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "expenses" }])
)(ExpenseList);
