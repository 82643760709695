import React from "react";
import { Paper, withStyles, Grid, TextField, Button } from "@material-ui/core";
import { Face, Fingerprint, Business, PhoneAndroid } from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUp } from "../../store/actions/authActions";
const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto",
      marginTop: theme.spacing(15)
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "auto",
      marginTop: theme.spacing(15)
    }
  },
  margin: {
    margin: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(1)
  }
});

class SignUp extends React.Component {
  state = {
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    address: "",
    suburb: "",
    city: "",
    areaCode: "",
    mobileNumber: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };

  render() {
    const { classes, auth } = this.props;
    if (auth.uid) return <Redirect to="/" />;

    return (
      <div className={classes.root}>
        <Paper className={classes.padding}>
          <div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  autoFocus
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="firstname"
                  label="First Name"
                  type="firstName"
                  fullWidth
                  autoFocus
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="lastname"
                  label="Last Name"
                  type="lastname"
                  fullWidth
                  autoFocus
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Fingerprint />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Business />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="address"
                  label="Address"
                  type="address"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Business />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="suburb"
                  label="Suburb"
                  type="suburb"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Business />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="city"
                  label="City"
                  type="city"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Business />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="areaCode"
                  label="Area Code"
                  type="areaCode"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <PhoneAndroid />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="mobileNumber"
                  label="Mobile Number"
                  type="number"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container justify="center" style={{ marginTop: "10px" }}>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={this.handleSubmit}
              >
                Sign Up
              </Button>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignUp));
