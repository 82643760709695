export const addExpense = expense => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const currentDate = Date.now();

    firestore
      .collection("expenses")
      .add({
        ...expense,
        date: currentDate
      })
      .then(() => {
        dispatch({ type: "ADD_EXPENSE", expense });
      })
      .catch(err => {
        dispatch({ type: "ADD_EXPENSE_ERROR", err });
      });
  };
};
