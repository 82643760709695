export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(({ user }) => {
        if (user) {
          user.getIdTokenResult().then(idTokenResult => {
            if (idTokenResult.claims.admin) {
              dispatch({ type: "LOGIN_SUCCESS" });
            } else {
              dispatch(signOut());
              dispatch({ type: "LOGIN_FAILED_NOT_ADMIN" });
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const checkIfAdmin = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    if (firebase.auth().currentUser !== null) {
      let user = firebase.auth().currentUser;
      if (user) {
        user.getIdTokenResult().then(idTokenResult => {
          if (idTokenResult.claims.admin) {
            dispatch({ type: "LOGIN_SUCCESS" });
          } else {
            dispatch(signOut());
            console.log("signed out");
            window.location.replace("/signIn");
          }
        });
      } else {
        dispatch(signOut());
      }
    }
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      });

    console.log("successfully signed out");
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users2")
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstname,
            lastName: newUser.lastname
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};
