const initState = {
  projects: []
};

const updatePaymentInfoReducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_PAYMENT_DONE": {
      console.log("UPDATE TRACKING INFO DONE " + action);
      return state;
    }
    case "UPDATE_PAYMENT_DONE_ERROR": {
      console.log("UPDATE TRACKING INFO ERROR" + action.ERROR);
      return state;
    }
    default:
      return state;
  }
};

export default updatePaymentInfoReducer;
