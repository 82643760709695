export const createOrder = order => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const currentDate = Date.now();
    let isPaid = false;
    let totalOwing = 0;
    let transactionLog = [];
    let paymentHistory = [];

    if (order.totalPaid > 0) {
      transactionLog.push({ date: currentDate, action: "order added" });
      transactionLog.push({ date: currentDate, action: "payment made " });
      paymentHistory = [{ payment: order.totalPaid, date: currentDate }];
    } else {
      transactionLog.push({ date: currentDate, action: "order added" });
      paymentHistory = [];
    }

    if (order.totalCost === Number(order.totalPaid)) {
      isPaid = true;
      totalOwing = 0;
      transactionLog.push({ date: currentDate, action: "payment complete" });
    } else {
      totalOwing = order.totalCost - order.totalPaid;
    }

    if (order.trackingNumber !== null && order.courierCompany !== null) {
      transactionLog.push({
        date: currentDate,
        action: "tracking info updated"
      });
    }

    firestore
      .collection("orders")
      .add({
        ...order,
        isPaid: isPaid,
        isCouriered: false,
        isComplete: false,
        isCancelled: false,
        history: transactionLog,
        totalOwing: totalOwing,
        payments: paymentHistory
      })
      .then(docRef => {
        console.log("Document written with ID: ", docRef.id);

        firestore
          .collection("payments")
          .add({
            orderId: docRef.id,
            email: order.email,
            items: order.items,
            date: currentDate,
            payment: order.totalPaid
          })
          .then(() => {
            dispatch({ type: "CREATE_PAYMENT" });
          })
          .catch(err => {
            dispatch({ type: "CREATE_PAYMENT_ERROR", err });
          });

        dispatch({ type: "CREATE_ORDER", order });
        window.location = "/inventory";
      })
      .catch(err => {
        dispatch({ type: "CREATE_ORDER_ERROR", err });
      });
  };
};
