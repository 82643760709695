import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  filter: {
    display: "flex",
    width: "50%",
    justifyContent: "space-between",
    padding: "1rem",
    margin: "0 auto",
    borderColor: "1px #c0c0c0 solid",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  filterSort: {
    // flex: "1"
  },
  filterCategory: {
    // flex: "1"
  }
});

class Filter extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.filter}>
        <div className={classes.filterSort}>
          Order
          <select value={this.props.sort} onChange={this.props.sortProducts}>
            <option>Alphabetical</option>
            <option value="lowest">Lowest</option>
            <option value="highest">Highest</option>
          </select>
        </div>
        <div className={classes.filterCategory}>
          <select
            value={this.props.category}
            onChange={this.props.filterProducts}
          >
            <option value=""> Show All</option>
            <option value="Necklace">Necklaces</option>
            <option value="Bracelet">Bracelets</option>
            <option value="Keyring">Keyrings</option>
            <option value="Marvel">Marvel</option>
            <option value="GOT">GOT</option>
            <option value="Harry Potter">Harry Potter</option>
          </select>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Filter);
