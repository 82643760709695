import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import { addExpense } from "../../store/actions/expenseActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import ExpenseList from "./ExpenseList";
import AddExpense from "./AddExpense";
import FilterExpenses from "./FilterExpenses";
const styles = theme => ({
  root: {
    width: "96%",
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto",
      marginTop: theme.spacing(10)
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      margin: "auto",
      marginTop: theme.spacing(10)
    }
  },
  gridItem: {
    height: 350
  }
});

class Expenses extends React.Component {
  state = {
    amount: "",
    name: "",
    nameFilter: "",
    dateFilter: "",
    yearFilter: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.addExpense(this.state); // dont need to save filter
    this.props.history.push("/expenses");
  };

  render() {
    const { classes, auth, expenses } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;
    return (
      <div className={classes.root}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item md={true} sm={true} xs={true} className={classes.gridItem}>
            <AddExpense
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            ></AddExpense>
          </Grid>

          <Grid item md={true} sm={true} xs={true} className={classes.gridItem}>
            <FilterExpenses
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            ></FilterExpenses>
          </Grid>
        </Grid>

        <ExpenseList
          className={classes.expenseList}
          expenseList={expenses}
          nameFilter={this.state.nameFilter}
          dateFilter={this.state.dateFilter}
          yearFilter={this.state.yearFilter}
        ></ExpenseList>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const expenses = state.firestore.ordered.expenses;

  return {
    auth: state.firebase.auth,
    id: ownProps.match.params.id,
    expenses: expenses
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addExpense: expense => dispatch(addExpense(expense))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "expenses" }])
)(withStyles(styles)(Expenses));
