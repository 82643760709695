import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    width: 800,
    margin: "0 auto"
  }
}));

const columns = [
  { field: "email", headerName: "Email", width: 200 },
  { field: "payment", headerName: "Payment", width: 100 },
  { field: "date", headerName: "Date", width: 500 }
];

export default function DataTable({ filteredPayments }) {
  const classes = useStyles();

  let rows = [];
  if (filteredPayments) {
    rows = filteredPayments.map(data => {
      return {
        id: data.id,
        email: data.email,
        date: new Date(data.date),
        payment: parseInt(data.payment)
      };
    });

    rows = rows.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  }
  return (
    <div className={classes.root}>
      {rows ? <DataGrid rows={rows} columns={columns} pageSize={100} /> : null}
    </div>
  );
}
