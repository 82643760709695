import React from "react";
import OrderCard from "./OrderCard";

export default function OrderList({ orders, filter, deliveryFilter }) {
  let output = "";

  if (filter === "") {
    filter = null;
  }

  if (orders) {

     let itemsSold = orders.map(order => {
        return order.items;  
     })

    let itemSoldArray = []

    itemsSold.forEach(itemsSold => {
      itemsSold.forEach(element => {
        itemSoldArray.push(element.name)   
        });
     })

    // console.log(itemSoldArray);

     const allItemsSoldWithCounts = itemSoldArray.reduce((acc,cur)=>{
      if(acc[cur]) acc[cur]++;
      else acc[cur] = 1;
      return acc;
    
    }, {});
    
    console.log(allItemsSoldWithCounts);

    // if no filters then show all orders that have not yet completes
    if (filter === null && deliveryFilter == null) {
      output = orders
        .filter(order => order.isComplete === false)
        .map(
          filteredOrder =>
            (output = (
              <div key={filteredOrder.id}>
                <OrderCard order={filteredOrder} />
              </div>
            ))
        );
    }
    // if delivery filter then show all orders that are not complete from that service
    else if (deliveryFilter && !filter) {
      if (deliveryFilter === "all") {
        output = orders.map(
          order =>
            (output = (
              <div key={order.id}>
                <OrderCard order={order} />
              </div>
            ))
        );
      } else if (deliveryFilter === "cancelled") {
        output = orders
          .filter(order => order.isCancelled === true)
          .map(
            order =>
              (output = (
                <div key={order.id}>
                  <OrderCard order={order} />
                </div>
              ))
          );
      } else if (deliveryFilter === "completed") {
        output = orders
          .filter(order => order.isComplete === true)
          .map(
            filteredOrder =>
              (output = (
                <div key={filteredOrder.id}>
                  <OrderCard order={filteredOrder} />
                </div>
              ))
          );
      } else {
        output = orders
          .filter(order =>
            order.isComplete === false
              ? order.courierCompany.includes(deliveryFilter)
              : null
          )
          .map(
            filteredOrder =>
              (output = (
                <div key={filteredOrder.id}>
                  <OrderCard order={filteredOrder} />
                </div>
              ))
          );
      }
    } // if delivery filter then show all orders that are not complete from that service
    else if (deliveryFilter && filter) {
      output = orders
        .filter(order =>
          order.isComplete === false
            ? order.courierCompany.includes(deliveryFilter) ||
              order.email.includes(filter)
            : null
        )
        .map(
          filteredOrder =>
            (output = (
              <div key={filteredOrder.id}>
                <OrderCard order={filteredOrder} />
              </div>
            ))
        );
    } else {
      // show in  complete orders that matches filter // make case sensitive
      return (output = orders
        .filter(
          order => order.isComplete === false && order.email.includes(filter)
        )
        .map(
          filteredOrder =>
            (output = (
              <div key={filteredOrder.id}>
                <OrderCard order={filteredOrder} />
              </div>
            ))
        ));
    }
  }
  return <div>{output}</div>;
}
