import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";
import fbConfig from "../../config/fbConfig";
import { checkIfAdmin } from "../../store/actions/authActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const handleMakeAdmin = e => {
  e.preventDefault();
  const adminEmail = document.querySelector("#admin-email").value;
  const addAdminRole = fbConfig.functions().httpsCallable("addAdminRole");
  addAdminRole({ email: adminEmail }).then(result => {
    console.log(result);
  });
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100vh",
    minHeight: "100vh",
    margin: "0 auto",
    justifyContent: "center"
  },
  header: {
    padding: "20px",
    color: "#3b5998",
    fontSize: "40px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px"
    }
  },
  logout: {
    marginTop: "50px",
    width: "40%"
  },
  makeAdmin: {
    marginLeft: "10px"
  }
}));

const Settings = props => {
  const classes = useStyles();
  const { auth } = props;
  props.checkIfAdmin();
  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div className={classes.root}>
      <Typography className={classes.header}> Settings </Typography>
      <Grid container justify="center" spacing={3}>
        <TextField
          id="admin-email"
          type="email"
          label="User Email"
          variant="outlined"
          required
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleMakeAdmin}
          className={classes.makeAdmin}
        >
          Make Admin
        </Button>
      </Grid>

      <Grid container justify="center">
        <Button
          variant="contained"
          color="secondary"
          onClick={props.signOut}
          className={classes.logout}
        >
          Log Out
        </Button>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    checkIfAdmin: user => dispatch(checkIfAdmin(user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
