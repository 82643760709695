import React from "react";
import { Paper, withStyles, Grid, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    background:
      "radial-gradient(circle, rgba(181,210,222,1) 0%, rgba(26,173,238,1) 46%, rgba(5,123,230,1) 100%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  header: {
    textAlign: "center",
    color: "#3b5998",
    fontSize: "28px",
    fontWeight: "Bold",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
      padding: "10px"
    }
  },
  margin: {
    margin: theme.spacing(1)
  },
  paper: {
    width: "60%",
    margin: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "0 auto"
    }
  },
  forgot: {
    margin: "0 auto",
    marginTop: "10px",
    color: "gray"
  },
  forgotText: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px"
    }
  },
  message: {
    textAlign: "center",
    color: "red",
    fontSize: "14px"
  },
  button: {
    width: "200px",
    height: "50px"
  }
});

class SignIn extends React.Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { classes } = this.props;
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/" />;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.margin}>
            <div className={classes.header}>Admin Login</div>
            <Grid container spacing={8}>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  className={classes.text}
                  InputLabelProps={{ required: false }}
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  InputLabelProps={{
                    required: false
                  }}
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "30px" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={this.handleSubmit}
              >
                Login
              </Button>
            </Grid>

            <Grid container alignItems="center" justify="space-between">
              <Grid item className={classes.forgot}>
                <Typography className={classes.forgotText}>
                  Forgot password?
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.message}>
            {authError ? <p>{authError}</p> : null}
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignIn));
