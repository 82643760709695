import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, TextField, Button } from "@material-ui/core";

const useStyles = makeStyles({
  addExpense: {
    padding: 15
  }
});

const AddExpense = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.addExpense}>
      <h3>Add Expenses</h3>

      <Grid container spacing={8} alignItems="flex-end">
        <Grid item md={true} sm={true} xs={true}>
          <TextField
            id="name"
            label="Name"
            fullWidth
            autoFocus
            required
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={8} alignItems="flex-end">
        <Grid item md={true} sm={true} xs={true}>
          <TextField
            id="amount"
            label="Amount"
            fullWidth
            autoFocus
            required
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" style={{ marginTop: "20px" }}>
        <Button
          variant="outlined"
          color="primary"
          background-color="primary"
          style={{ textTransform: "none" }}
          onClick={e => props.handleSubmit(e)}
        >
          Add Expense
        </Button>
      </Grid>
    </Paper>
  );
};

export default AddExpense;
