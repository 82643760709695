import React, { useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import ItemCard from "./ItemCard";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import Filter from "./Filter";
import NewCart from "./NewCart";
import InventoryButtons from "./InventoryButtons";
import ShopOverview from "./ShopOverview";
import { checkIfAdmin } from "../../store/actions/authActions";
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "100px",
    color: "#3b5998"
  },
  products: {
    margin: "0 auto"
  },
  main: {
    display: "flex",
    flexWrap: "wrap"
  },
  cartContainer: {
    position: "fixed",
    top: 0,
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    overflowX: "hidden"
  },
  cart: {
    margin: "0 auto",
    paddingTop: "200px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "100px"
    }
  },
  grid: {
    margin: "0 auto",
    justifyContent: "center"
  },
  filter: {
    margin: "0 auto"
  }
}));

const Inventory = props => {
  const classes = useStyles();
  let { inventory, auth } = props;

  checkIfAdmin();
  if (!auth.uid) window.location.href = "/signIn";

  let count = 0;

  inventory ? (count = inventory.length) : (count = 0);

  const [revealCart, setReveal] = useState(false);

  const [filterValue, setFilterValue] = useState(null);
  const [sortValue, setSortValue] = useState(null);

  let storedItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  const [cartValue, setCartValue] = useState(storedItems);

  function calcTotalItemsAvailable() {
    let total = 0;
    inventory.forEach(item => {
      total = Number(total) + Number(item.amountAvailable);
    });
    return total;
  }

  function calcTotalItemAwaiting() {
    let total = 0;
    inventory.forEach(item => {
      if (item.amountAwaiting) {
        total = Number(total) + Number(item.amountAwaiting);
      }
    });
    return total;
  }

  let totalItemsAvailable,
    totalItemsAwaiting = 0;

  inventory
    ? (totalItemsAvailable = calcTotalItemsAvailable())
    : (totalItemsAvailable = 0);

  inventory
    ? (totalItemsAwaiting = calcTotalItemAwaiting())
    : (totalItemsAwaiting = 0);

  const filterProducts = event => {
    setFilterValue(event.target.value);
  };

  const sortProducts = event => {
    setSortValue(event.target.value);
  };

  const showCart = () => {
    setReveal(true);
  };
  const hideCart = () => {
    setReveal(false);
  };

  const removeFromCart = product => {
    const cartItems = cartValue.slice(); // clone copy
    setCartValue(cartItems.filter(x => x.id !== product.id));
    localStorage.setItem(
      "cartItems",
      JSON.stringify(cartItems.filter(x => x.id !== product.id))
    );
  };

  const addToCart = product => {
    const cartItems = cartValue.slice();
    let alreadyInCart = false;
    cartItems.forEach(item => {
      if (item.id === product.id) {
        item.count++;
        alreadyInCart = true;
      }
    });
    if (!alreadyInCart) {
      cartItems.push({ ...product, count: 1 });
    }
    setCartValue(cartItems);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  return (
    <div className={classes.root}>
      <ShopOverview
        uniqueItemsCount={count}
        totalItemsAvailable={totalItemsAvailable}
        totalItemsAwaiting={totalItemsAwaiting}
      ></ShopOverview>

      <div className={classes.main}>
        <Filter
          count={count}
          filterProducts={filterProducts}
          sortProducts={sortProducts}
          className={classes.filter}
        ></Filter>
        <div className={classes.products}>
          <GridList cols={3} className={classes.grid}>
            {filterValue
              ? inventory &&
                inventory
                  .filter(item =>
                    filterValue ? item.tags.includes(filterValue) : null
                  )
                  .slice()
                  .sort((a, b) =>
                    sortValue === "lowest"
                      ? a.price > b.price
                        ? 1
                        : -1
                      : sortValue === "highest"
                      ? a.price < b.price
                        ? 1
                        : -1
                      : a.name > b.name
                      ? 1
                      : -1
                  )
                  .map(item => {
                    return <ItemCard item={item} key={item.code}></ItemCard>;
                  })
              : sortValue
              ? inventory &&
                inventory
                  .slice()
                  .sort((a, b) =>
                    sortValue === "lowest"
                      ? a.price > b.price
                        ? 1
                        : -1
                      : sortValue === "highest"
                      ? a.price < b.price
                        ? 1
                        : -1
                      : a.name > b.name
                      ? 1
                      : -1
                  )
                  .map(item => {
                    return (
                      <ItemCard
                        item={item}
                        key={item.code}
                        add={addToCart}
                      ></ItemCard>
                    );
                  })
              : inventory &&
                inventory.map(item => {
                  return (
                    <ItemCard
                      item={item}
                      key={item.code}
                      add={addToCart}
                    ></ItemCard>
                  );
                })}
          </GridList>
        </div>

        {revealCart ? (
          <div className={classes.cartContainer}>
            <div className={classes.cart}>
              <NewCart
                cartItems={cartValue}
                remove={removeFromCart}
                hide={hideCart}
              />
            </div>
          </div>
        ) : (
          <InventoryButtons
            showCart={showCart}
            cartValue={cartValue}
          ></InventoryButtons>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    inventory: state.firestore.ordered.inventory,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkIfAdmin: user => dispatch(checkIfAdmin(user))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([{ collection: "inventory" }])
)(Inventory);
