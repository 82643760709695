const initState = {
  projects: []
};

const markAsDoneReducer = (state = initState, action) => {
  switch (action.type) {
    case "MARK_AS_DONE": {
      console.log("ORDER IS MARKED AS DONE " + action.id);
      return state;
    }
    case "MARK_AS_DONE_ERROR": {
      console.log("MARK AS DONE ERROR" + action.ERROR);
      return state;
    }
    default:
      return state;
  }
};

export default markAsDoneReducer;
