export const createProduct = product => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const currentDate = Date.now();
    let logs = [];

    logs.push({
      currentDate: currentDate,
      action: "create",
      amount: Number(product.price),
      amountAvailable: Number(product.amountAvailable)
    });

    // file does not need to be stored in the db
    delete product.file;

    firestore
      .collection("inventory")
      .add({
        ...product,
        amountSold: 0,
        isVisible: false,
        sale: false,
        logs: logs
      })
      .then(() => {
        console.log("created");
        dispatch({ type: "CREATE_PRODUCT", product });
      })
      .catch(err => {
        console.log("Error");
        dispatch({ type: "CREATE_PRODUCT_ERROR", err });
      });
  };
};

export const updateProduct = product => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let logs = [];
    const currentDate = Date.now();

    firestore
      .collection("inventory")
      .doc(product.id)
      .get()
      .then(doc => {
        if (doc.exists) {
          logs = doc.data().logs;
          logs.push({
            currentDate: currentDate,
            action: "updated",
            amount: Number(product.price),
            amountAvailable: Number(product.amountAvailable)
          });

          firestore
            .collection("inventory")
            .doc(product.id)
            .update({
              name: product.name,
              amountAvailable: Number(product.amountAvailable),
              amountAwaiting: Number(product.amountAwaiting),
              description: product.description,
              price: Number(product.price),
              tags: product.tags,
              logs: logs,
              isVisible: product.isVisible,
              sale: product.sale,
              salePrice: Number(product.salePrice)
            })
            .then(() => {
              console.log("updated");
              dispatch({ type: "UPDATE_PRODUCT", product });
            })
            .catch(err => {
              console.log("error");
              dispatch({ type: "UPDATE_PRODUCT_ERROR", err });
            });
        } else {
          console.log("No such document!");
        }
      });
  };
};

export const updateProductCount = product => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    let logs = [];
    const currentDate = Date.now();

    product.forEach(item => {
      firestore
        .collection("inventory")
        .where("name", "==", item.name)
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(product => {
            let inventoryItem = { ...product.data(), ["id"]: product.id };

            inventoryItem.amountAvailable =
              inventoryItem.amountAvailable - item.count;

            logs = inventoryItem.logs || [];
            logs.push({
              currentDate: currentDate,
              action: "sold",
              amount: item.count,
              amountAvailable: Number(inventoryItem.amountAvailable)
            });

            firestore
              .collection("inventory")
              .doc(product.id)
              .update({
                amountAvailable: Number(inventoryItem.amountAvailable),
                logs: logs
              });
          });
          dispatch({ type: "UPDATE_PRODUCT_COUNT", product });
        })
        .catch(err => {
          dispatch({ type: "UPDATE_PRODUCT_COUNT_ERROR", err });
        });
    });
  };
};
