export const updateTrackingInfo = info => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const currentDate = Date.now();
    let historyArray = [];
    var docRef = firestore.collection("orders").doc(info.id);
    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          historyArray = doc.data().history;
          historyArray.push({
            date: currentDate,
            action: "tracking info updated"
          });
          firestore
            .collection("orders")
            .doc(info.id)
            .update({
              courierCompany: info.courierCompany,
              trackingNumber: info.trackingNumber,
              history: historyArray
            })
            .then(() => {
              dispatch({ type: "UPDATE_TRACKING_DONE" });
            })
            .catch(err => {
              dispatch({ type: "UPDATE_TRACKING_ERROR", err });
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  };
};
