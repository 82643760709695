import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { markAsDone } from "../../store/actions/markAsDoneActions";
import { cancelOrder } from "../../store/actions/cancelOrderActions";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Grid from "@material-ui/core/Grid";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import DoneIcon from "@material-ui/icons/Done";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PaymentIcon from "@material-ui/icons/Payment";
import CloseIcon from "@material-ui/icons/Close";

let trackingNumber = "";
let trackingUrl = "";
const tablet = 640;
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 5,
    color: "black"
  },

  order: {
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10
  },

  paper: {
    padding: 1,
    textAlign: "center",
    color: theme.palette.text.secondary
  },

  cardContent: {
    "&:last-child": {
      paddingBottom: 0
    }
  },

  title: {
    width: "80%"
  },

  email: {
    fontSize: 14,
    paddingBottom: 5,
    color: "#3685B5",
    fontWeight: 700,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 14
    }
  },

  note: {
    fontSize: 12,
    paddingBottom: 5,
    color: "red",
    fontWeight: 700,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 12
    }
  },

  info: {
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 16
    }
  },
  cost: {
    fontSize: 14,
    color: "#E5A718",
    fontWeight: 700,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 16
    }
  },

  items: {
    marginBottom: 8,
    fontSize: 12,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 14
    }
  },

  message: {
    fontSize: 13,
    paddingTop: 5,
    color: "firebrick"
  },

  trackingNumberLink: {
    fontSize: 10,
    color: "#3b5998",
    [theme.breakpoints.up(tablet)]: {
      fontSize: 13
    }
  },

  markAsDone: {
    color: "white",
    backgroundColor: "#3b5998",
    padding: 10,
    marginTop: 10,
    textAlign: "center",
    fontSize: 14
  },

  trackingDetails: {
    height: "100vh",
    width: "100%",
    backgroundColor: "red"
  },
  icon: {
    color: "gray",
    fontSize: 19
  },
  bottomNav: {
    paddingTop: 10,
    paddingBottom: 10
  }
}));

const handleClick = (e, trackingLink) => {
  e.preventDefault();
  window.open(trackingLink, "_blank");
};

const OrderCard = ({ order, settings, markAsDone, cancelOrder }) => {

  console.log("isWebsiteOrder: ",order.isWebsiteOrder)

  let orderItems =  order.items;

  orderItems.forEach(element => {
    console.log("name: ",element.name)
    console.log("price: ",element.price)
    console.log("count: ", element.count)
  });
  console.log("============================")


  const updateStatusToDone = e => {
    e.preventDefault();
    if (order.totalOwing > 0) {
      alert("Cannot close until payment has been made.");
    } else {
      markAsDone(order.id);
    }
  };

  const handleDelete = e => {
    e.preventDefault();
    cancelOrder(order.id);
  };

  const items = order.items;
  let itemNames = items.map(item => {
    return <div>
      <div>{item.name} {item.count ? " - Qty: " + item.count: " - Qty:1"} </div>
      <div>{item.discount > 0 ? "Discount:" + item.discount : null} </div>
      </div>;
  });

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  let month = months[new Date(order.dateCreated).getMonth()] || "";
  let year = new Date(order.dateCreated).getFullYear() || "";

  const classes = useStyles();
  let trackingDetails = "";
  let paymentDetails = "";
  let trackingNumberLink = "";
  let closeOrder = "";
  let trackingLink = "";

  if (order.courierCompany) {
    let courierService = order.courierCompany;
    order.trackingNumber
      ? (trackingNumber = order.trackingNumber)
      : (trackingNumber = "");
    if (settings) {
      if (
        settings[0].deliveryInfo &&
        settings[0].deliveryInfo[courierService]
      ) {
        trackingUrl = settings[0].deliveryInfo[courierService].trackingUrl;
        trackingUrl = trackingUrl.replace(/ /g, "");
        trackingLink = trackingUrl + trackingNumber;
      }

      // only show this button if there is a tracking number
      if (order.trackingNumber !== null) {
        trackingNumberLink = (
          <div
            className={classes.trackingNumberLink}
            onClick={e => handleClick(e, trackingLink)}
          >
            {trackingNumber}
          </div>
        );

        closeOrder = (
          <BottomNavigationAction
            icon={<DoneIcon />}
            onClick={e => updateStatusToDone(e)}
          ></BottomNavigationAction>
        );
      } else {
        trackingDetails = (
          <BottomNavigationAction
            component={Link}
            to={"/tracking/" + order.id}
            key={order.id}
            icon={<LocalShippingIcon />}
          ></BottomNavigationAction>
        );
      }
    }
  } else {
    trackingDetails = (
      <BottomNavigationAction
        component={Link}
        to={"/tracking/" + order.id}
        icon={<LocalShippingIcon />}
      ></BottomNavigationAction>
    );
  }

  if (!order.isPaid) {
    paymentDetails = (
      <BottomNavigationAction
        component={Link}
        to={"/payment/" + order.id}
        icon={<PaymentIcon />}
      ></BottomNavigationAction>
    );
  } else {
    paymentDetails = null;
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    {order.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {trackingNumberLink}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography
            className={classes.email}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {order.email}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.items}
          >
            {itemNames}
          </Typography>

          {order.note ? (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.note}
            >
              {order.note}
            </Typography>
          ) : null}
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.info}
          >
            Cost: R{order.totalCost}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.cost}
          >
            Paid: R{order.totalPaid}
          </Typography>

          <Typography
            className={classes.message}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {month} {year}
          </Typography>

          <BottomNavigation className={classes.bottomNav} showLabels>
            {paymentDetails}
            {trackingDetails}
            {closeOrder}
            <BottomNavigationAction
              component={Link}
              to={"/order/" + order.id}
              icon={<ChevronRightIcon />}
            ></BottomNavigationAction>

            <BottomNavigationAction
              onClick={handleDelete}
              icon={<CloseIcon />}
            ></BottomNavigationAction>
          </BottomNavigation>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    settings: state.firestore.ordered.settings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    markAsDone: id => dispatch(markAsDone(id)),
    cancelOrder: id => dispatch(cancelOrder(id))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),

  firestoreConnect([{ collection: "settings" }])
)(OrderCard);
