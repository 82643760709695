export const cancelOrder = id => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const currentDate = Date.now();
    //This is a hard delete.
    /*
    firestore
      .collection("orders")
      .doc(id)
      .delete()
      .then(function() {
        dispatch({ type: "DELETE_ORDER_DONE" });
      })
      .catch(function(error) {
        dispatch({ type: "DELETE_ORDER_ERROR", error });
      });
  };
  */

    // instead lets set order to complete and status to cancelled.

    let historyArray = [];
    var docRef = firestore.collection("orders").doc(id);

    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          historyArray = doc.data().history;

          historyArray.push({
            date: currentDate,
            action: "order has been completed"
          });

          historyArray.push({
            date: currentDate,
            action: "order has been cancelled"
          });
          firestore
            .collection("orders")
            .doc(id)
            .update({
              isComplete: true,
              isCancelled: true,
              history: historyArray
            })
            .then(function() {
              dispatch({ type: "DELETE_ORDER_DONE" });
            })
            .catch(function(error) {
              dispatch({ type: "DELETE_ORDER_ERROR", error });
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  };
};
