import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "2px"
    }
  },
  infoCard: {
    fontSize: "100px",
    fontWeight: "bold",
    color: "#3b5998",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
      paddingTop: "2px",
      paddingBottom: "2px"
    }
  },
  subtext: {
    fontSize: "20px",
    fontWeight: "bold",
    padding: "5px",
    color: "#3b5998",
    textAlign: "center"
  }
}));

const UserInfo = ({ users }) => {
  const classes = useStyles();
  let totalNumberOfUsers = 0;

  if (users !== undefined) {
    totalNumberOfUsers = users.length;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.paperContent}>
        <Typography className={classes.infoCard}>
          {totalNumberOfUsers}
        </Typography>
        <Typography className={classes.subtext}>Registered Users</Typography>
      </div>
    </Paper>
  );
};

export default UserInfo;
