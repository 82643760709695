import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "20px",
    marginBottom: "10px",
    color: "#3b5998",
    width: "80%",
    margin: "0 auto"
  },
  overview: {
    fontSize: "30px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px"
    }
  }
}));

const ShopOverview = props => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.overview}>Shop Overview</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Total Number of Unique Items: {props.uniqueItemsCount}
        </Typography>

        <Typography variant="body2" color="textSecondary" component="p">
          Total Amount of Items in Stock : {props.totalItemsAvailable}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Total Amount of Items Still Awaiting: {props.totalItemsAwaiting}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShopOverview;
