import React from "react";
import { BrowserRouter } from "react-router-dom";

import Drawer from "./components/layout/Drawer";
function App() {
  window.onbeforeunload = e => {
    localStorage.setItem("hasDashboardLoaded", false);
    localStorage.setItem("hasUserLoaded", false);
  };

  return (
    <BrowserRouter>
      <div id="App" style={{ overflow: "Hidden", backgroundColor: "#f5f5f5" }}>
        <Drawer />
      </div>
    </BrowserRouter>
  );
}

export default App;
