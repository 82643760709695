export const updatePaymentInfo = info => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    var docRef = firestore.collection("orders").doc(info.id);
    const currentDate = Date.now();
    let orderId = null;
    let totalPaid = 0;
    let totalOwing = 0;
    let updatedTotalOwing = 0;
    let updatedTotalPaid = 0;
    let updatedIsPaid;
    let newPayment = Number(info.payment);
    let paymentsArray = [];
    let historyArray = [];
    let email = [];
    let items = [];

    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          if (doc.data().isPaid === false) {
            orderId = doc.id;
            email = doc.data().email;
            items = doc.data().items;
            totalPaid = Number(doc.data().totalPaid);
            totalOwing = Number(doc.data().totalOwing);
            paymentsArray = doc.data().payments;
            historyArray = doc.data().history;
            paymentsArray.push({ payment: info.payment, date: currentDate });
            historyArray.push({ date: currentDate, action: "payment added" });

            updatedTotalOwing = totalOwing - newPayment;
            updatedTotalPaid = totalPaid + newPayment;

            if (updatedTotalOwing === 0) {
              updatedIsPaid = true;
              historyArray.push({
                date: currentDate,
                action: "payment complete"
              });
            } else updatedIsPaid = false;

            if (newPayment > totalOwing) {
              alert(
                "Payment not added. Payment can not be greater than amount owing. Please try again"
              );
            } else {
              firestore
                .collection("orders")
                .doc(info.id)
                .update({
                  payments: paymentsArray,
                  history: historyArray,
                  totalOwing: updatedTotalOwing,
                  totalPaid: updatedTotalPaid,
                  isPaid: updatedIsPaid
                })
                .then(() => {
                  firestore.collection("payments").add({
                    orderId: doc.id,
                    email: email,
                    items: items,
                    date: currentDate,
                    payment: newPayment
                  });
                  dispatch({ type: "UPDATE_PAYMENT_DONE" });
                })
                .catch(err => {
                  dispatch({ type: "UPDATE_PAYMENT_ERROR", err });
                });
            }
          } else {
            alert("You have already paid in full");
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  };
};
