import React from "react";
import { Paper, withStyles, Grid, TextField } from "@material-ui/core";
import { createProduct } from "../../store/actions/productActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    margin: "auto",
    padding: "20px",
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(8)
    },
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      marginTop: theme.spacing(15)
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      marginTop: theme.spacing(10)
    }
  },

  form: {
    backgroundColor: "white",
    width: "80%",
    margin: "auto",
    padding: "20px",

    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },

  padding: {
    padding: theme.spacing(1)
  },
  text: {
    paddingBottom: "20px"
  },
  checkbox: {
    paddingBottom: "20px",
    marginLeft: "7px",
    fontSize: "15px"
  },

  label: {
    fontSize: "15px",
    marginTop: "10px",
    display: "inline-block"
  },

  heading: {
    color: "#3b5998",
    marginLeft: "10px",
    fontSize: "35px",
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      paddingTop: "10px",
      paddingBottom: "10px"
    }
  }
});

class CreateProduct extends React.Component {
  state = {
    name: "",
    description: "",
    price: 0,
    code: "",
    amountAvailable: 0,
    tags: [],
    file: null
  };

  handleChange = e => {
    let value = e.target.value;
    if (e.target.id === "price" || e.target.id === "amountAvailable") {
      value = Number(e.target.value);
    }

    this.setState({
      [e.target.id]: value
    });
  };

  handleSubmit = async e => {
    e.preventDefault();

    let file = this.state.file;

    if (file) {
      let formData = new FormData();
      formData.append("avatar", file);

      let response = await axios.post(
        "https://www.durbancomics.com/upload.php",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data"
          }
        }
      );

      let code = response.data.code.toString() || "test";
      if (code) {
        code = code.substring(0, code.indexOf("."));
        this.setState({
          code: code
        });
      }
    }
    this.props.createProduct(this.state);
    this.props.history.push("/inventory");
  };

  uploadFile = async event => {
    let file = event.target.files[0];

    this.setState({
      file: file
    });
  };

  render() {
    const { classes, auth } = this.props;
    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <Paper className={classes.root}>
        <Typography className={classes.heading}>Add Product</Typography>

        <form
          id="addOrderForm"
          className={classes.form}
          onSubmit={this.handleSubmit}
        >
          <TextField
            id="name"
            variant="outlined"
            label=" Name"
            type="name"
            fullWidth
            autoFocus
            required
            className={classes.text}
            onChange={this.handleChange}
          />

          <TextField
            id="description"
            variant="outlined"
            label=" Description"
            type="description"
            fullWidth
            autoFocus
            required
            className={classes.text}
            onChange={this.handleChange}
          />

          <TextField
            id="price"
            variant="outlined"
            label=" Price"
            type="number"
            fullWidth
            autoFocus
            required
            className={classes.text}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            id="amountAvailable"
            label="Amount Available"
            type="amountAvailable"
            fullWidth
            required
            className={classes.text}
            onChange={this.handleChange}
          />

          <TextField
            variant="outlined"
            id="tags"
            label="tags"
            type="tags"
            fullWidth
            required
            className={classes.text}
            onChange={this.handleChange}
          />

          <input type="file" name="file" onChange={this.uploadFile} />

          <Grid container justify="center">
            <Tooltip title="Add" aria-label="add" type="submit">
              <Fab color="primary" className={classes.fab}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createProduct: product => dispatch(createProduct(product))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CreateProduct));
