import React, { Component } from "react";
import classes from "./Dashboard.module.css";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import OrderOverview from "../order/OrderOverview";
import { Redirect } from "react-router-dom";
import OrderList from "../order/OrderList";
import { TextField } from "@material-ui/core";
import CardLoader from "../layout/Loader";
import Button from "@material-ui/core/Button";
import { checkIfAdmin } from "../../store/actions/authActions";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      deliveryFilter: null
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  setDeliveryFilter = (e, value) => {
    this.setState({
      deliveryFilter: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  render() {
    const { orders, auth, users, checkIfAdmin } = this.props;

    checkIfAdmin();
    if (!auth.uid) return <Redirect to="/signin" />;

    let content = "";

    if (!orders) {
      content = (
        <div className={classes.contentLoader}>
          <CardLoader />
        </div>
      );
    } else {
      content = (
        <div className={classes.dashboardContent}>
          <div className={classes.overview}>
            <OrderOverview orders={orders} />
          </div>

          <div className={classes.orders}>
            <div className={classes.search}>
              <TextField
                className={classes.searchBar}
                variant="outlined"
                id="filter"
                label="Search"
                type="filter"
                fullWidth
                autoFocus
                onChange={this.handleChange}
              />

              <div className={classes.searchButtonContainer}>
                <Button
                  className={classes.buttonSearch}
                  id="fastway"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, "fastway");
                  }}
                >
                  Fastway
                </Button>

                <Button
                  className={classes.buttonSearch}
                  id="postnet"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, "postnet");
                  }}
                >
                  Postnet
                </Button>

                <Button
                  className={classes.buttonSearch}
                  id="all"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, null);
                  }}
                >
                  Current
                </Button>

                <Button
                  className={classes.buttonSearch}
                  id="completed"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, "completed");
                  }}
                >
                  Completed
                </Button>

                <Button
                  className={classes.buttonSearch}
                  id="all"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, "cancelled");
                  }}
                >
                  Cancelled
                </Button>

                <Button
                  className={classes.buttonSearch}
                  id="all"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    this.setDeliveryFilter(e, "all");
                  }}
                >
                  All
                </Button>
              </div>
            </div>

            <OrderList
              orders={orders}
              filter={this.state.filter}
              deliveryFilter={this.state.deliveryFilter}
            />
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  }
}

const mapStateToProps = state => {
  return {
    orders: state.firestore.ordered.orders,
    users: state.firestore.ordered.users,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkIfAdmin: user => dispatch(checkIfAdmin(user))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect([
    { collection: "orders" },
    { collection: "settings" },
    { collection: "users", orderBy: ["email"] }
  ])
)(Dashboard);
