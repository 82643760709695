import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: 200,
    padding: 10,
    fontSize: 20,
    margin: 10,
    [theme.breakpoints.down("md")]: {
      width: 240
    },
    [theme.breakpoints.down("sm")]: {
      width: 140
    }
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 260,
    marginBottom: 20,
    height: 500
  },
  image: {
    width: 180,
    height: 180,
    [theme.breakpoints.down("md")]: {
      width: 220,
      height: 220
    },
    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 120
    }
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  name: {
    color: "#3b5998",
    fontSize: 13,
    margin: 0,
    height: "30px"
  },
  description: {
    color: "gray",
    lineHeight: 2,
    fontSize: 10
  },
  available: {
    color: "#5f6368",
    lineHeight: 2,
    fontSize: 13
  },
  price: {
    color: "#222",
    lineHeight: 2,
    fontSize: 16,
    fontWeight: 800,
    paddingTop: 10
  },
  btn: { padding: 10 },
  edit: {
    color: "#3498db",
    position: "absolute",
    top: 0,
    right: 0
  },
  add: {
    color: "#3498db"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  CardContent: {
    padding: 2
  }
}));

const ItemCard = props => {
  let { item } = props;
  const classes = useStyles();
  let domain = "https://durbancomics.com/products/";
  let imageLink = domain + item.code + ".png";
  let updatedAmountAvailable = 0;
  const [amountAvailable, setAmountAvailable] = useState(item.amountAvailable);

  const addToCart = item => {
    if (amountAvailable > 0) {
      updatedAmountAvailable = amountAvailable - 1;
      setAmountAvailable(updatedAmountAvailable);
      props.add(item);
    } else {
      alert("Cant add more than available");
    }
  };

  return (
    <Card className={classes.root}>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.name}
      >
        {item.name}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.price}
      >
        R{item.price}
      </Typography>

      <CardMedia
        className={classes.image}
        image={imageLink}
        title={item.name}
      />
      <CardContent className={classes.CardContent}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.description}
        >
          {item.description}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.available}
        >
          Items Available: {amountAvailable}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.available}
        >
          Awaiting: {item.amountAwaiting}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="addToCart" onClick={() => addToCart(item)}>
          <AddShoppingCartIcon className={classes.add} />
        </IconButton>

        <IconButton aria-label="edit" onClick={props.showCart}>
          <Link
            to={{ pathname: "/UpdateProduct", query: { docId: item.id } }}
            className={classes.edit}
          >
            <EditIcon />
          </Link>
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default ItemCard;
