import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
const tablet = 640;
const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 10,
    color: "#3b5998"
  },
  paperContent: {
    margin: 10,
    padding: 10
  },
  notifications: {
    padding: 5,
    textAlign: "center",
    margin: "0 auto",
    marginBottom: 10,
    fontWeight: 700,
    color: "#E5A718"
  },
  notificationHeader: {
    display: "inline-block",
    marginLeft: 10,
    fontSize: 35,
    [theme.breakpoints.up("sm")]: {
      fontSize: 35
    }
  },
  notificationText: {
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      fontSize: 16
    }
  },
  card: {
    color: "#3685B5",
    height: 230,
    [theme.breakpoints.down("md")]: {
      height: 220
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto"
    }
  },

  cardHeader: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#3685B5",
    [theme.breakpoints.up(tablet)]: {
      fontSize: 18
    }
  },
  info: {
    fontSize: 14,
    paddingBottom: 1,
    [theme.breakpoints.up(tablet)]: {
      fontSize: 16
    }
  },
  message: {
    fontSize: 14,
    fontWeight: "bold",
    color: "green",
    [theme.breakpoints.up(tablet)]: {
      fontSize: 16
    }
  },
  alert: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10,
    color: "firebrick"
  }
}));

const OrderOverview = ({ orders }) => {
  const classes = useStyles();
  let totalNumberOfOrders = 0;
  let numberOfCurrentOrders = 0;
  let numberOfCompletedOrders = 0;
  let numberOfCancelledOrders = 0;
  let numberOfNewOrders = 0;
  let numberOfOrdersThisMonth = 0;

  let overallOutstanding = 0;
  let overallCost = 0;
  let overallReceived = 0;
  let totalOpenOutstanding = 0;
  let totalOpenCost = 0;
  let totalOpenReceived = 0;
  let fastwayCount = 0;
  let accountsMessage = "";
  let accountsMessageAlert = false;
  let salesThisMonth = 0;

  if (orders !== undefined) {
    totalNumberOfOrders = orders.length;

    orders.forEach(item => {
      if (!item.trackingNumber) {
        numberOfNewOrders++;
      }

      let currentDate = new Date().toISOString().slice(0, 7);
      let itemDate = new Date(item.history[0].date).toISOString().slice(0, 7);

      if (currentDate === itemDate) {
        numberOfOrdersThisMonth = numberOfOrdersThisMonth + 1;
        salesThisMonth = salesThisMonth + item.totalCost;
      }

      if (!item.isCancelled) {
        overallOutstanding = overallOutstanding + Number(item.totalOwing);
        overallCost = overallCost + Number(item.totalCost);
        overallReceived = overallReceived + Number(item.totalPaid);
      } else {
        numberOfCancelledOrders++;
      }

      if (!item.isComplete) {
        totalOpenOutstanding = totalOpenOutstanding + Number(item.totalOwing);
        totalOpenCost = totalOpenCost + Number(item.totalCost);
        totalOpenReceived = totalOpenReceived + Number(item.totalPaid);
        numberOfCurrentOrders++;

        if (item.courierCompany) {
          if (item.courierCompany === "fastway") {
            fastwayCount = fastwayCount + 1;
          }
        }
      }
      numberOfCompletedOrders =
        totalNumberOfOrders - numberOfCurrentOrders - numberOfCancelledOrders;

      if (totalOpenReceived + totalOpenOutstanding !== totalOpenCost) {
        accountsMessage = "Check Accounts";
        accountsMessageAlert = true;
      } else {
        accountsMessage = "Totals Correct";
        accountsMessageAlert = false;
      }
    });
  }

  return (
    <div className={classes.paperContent}>
      <Card className={classes.notifications}>
        <div className={classes.notificationHeader}>{numberOfNewOrders}</div>
        <div className={classes.notificationText}>New Orders</div>
      </Card>

      <Card className={classes.notifications}>
        <div className={classes.notificationHeader}>
          {numberOfOrdersThisMonth}
        </div>
        <div className={classes.notificationText}>Orders this Month</div>
      </Card>

      <Card className={classes.notifications}>
        <div className={classes.notificationHeader}>R {salesThisMonth}</div>
        <div className={classes.notificationText}>Sales this Month</div>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} lg={12}>
          <Card className={classes.card}>
            <CardContent className={classes.CardContent}>
              <Typography className={classes.cardHeader}>Overall</Typography>
              <Typography className={classes.info}>
                Sales: R{overallCost}
              </Typography>
              <Typography className={classes.info}>
                Received: R{overallReceived}
              </Typography>
              <Typography className={classes.info}>
                Outstanding: R{overallOutstanding}
              </Typography>

              <Typography className={classes.info}>
                Total: {totalNumberOfOrders}
              </Typography>

              <Typography className={classes.info}>
                Completed: {numberOfCompletedOrders}
              </Typography>
              <Typography className={classes.info}>
                Cancelled: {numberOfCancelledOrders}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} lg={12}>
          <Card className={classes.card}>
            <CardContent className={classes.CardContent}>
              <Typography className={classes.cardHeader}>Open</Typography>
              <Typography className={classes.info}>
                Sales: R{totalOpenCost}
              </Typography>
              <Typography className={classes.info}>
                Received: R{totalOpenReceived}
              </Typography>
              <Typography className={classes.info}>
                Outstanding: R{totalOpenOutstanding}
              </Typography>
              <Typography className={classes.info}>
                Orders: {numberOfCurrentOrders}
              </Typography>
              <Typography className={classes.info}>
                Fastway: {fastwayCount}
              </Typography>
              <Typography
                className={
                  !accountsMessageAlert ? classes.message : classes.alert
                }
              ></Typography>

              <Typography className={classes.message}>
                {accountsMessage}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderOverview;
