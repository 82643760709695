const initState = {
  projects: []
};

const cancelOrderReducer = (state = initState, action) => {
  switch (action.type) {
    case "DELETE_ORDER_DONE": {
      console.log("DELETED ORDER" + action);
      return state;
    }
    case "CREATE_ORDER_ERROR": {
      console.log("DELETE ORDER ERROR" + action.ERROR);
      return state;
    }
    default:
      return state;
  }
};

export default cancelOrderReducer;
