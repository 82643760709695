import React, { useState, useEffect } from "react";
import UserSummary from "./UserSummary";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import UserInfo from "./UserInfo";
import classes from "./UserList.module.css";
import Loader from "../layout/Loader";
import { Redirect } from "react-router-dom";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const UserList = props => {

  const { users, auth } = props;
  const [filterValue, setFilterValue] = useState("");
  const [emails, setEmails] = useState([]);
  let csv = ["Email"];
  var emailString;

  useEffect(() => {
    if (users) {
      console.log(users)
      users.map(({ email }) => {
        csv.push(email);
      })

      emailString = csv.map(function (d) {
        return JSON.stringify(d);
      })
        .join('\n')

        setEmails(emailString);
    }
  }, [users])


  const downloadUserList = () => {

    // (C) CREATE BLOB OBJECT
    var myBlob = new Blob([emails], { type: "text/csv" });

    // (D) CREATE DOWNLOAD LINK
    var url = window.URL.createObjectURL(myBlob);
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "durbancomicsusers.csv";

    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
  }

  if (!auth.uid) return <Redirect to="/signin" />;

  const updateFilter = e => {
    setFilterValue(e.target.value);
  };

  return (
    <div>

      <div className={classes.search}>

        <Button
           className={classes.downloadButton}
          id="downloadUserist"
          variant="outlined"
          color="primary"
          onClick={downloadUserList}
        >
          Download Mailing List
        </Button>

        <br /><br/>

        <TextField
          className={classes.searchBar}
          variant="outlined"
          id="filter"
          label="Seach by email"
          type="filter"
          fullWidth
          autoFocus
          onChange={updateFilter}
        />
      </div>

      {users ? (
        <div className={classes.userContent}>
          <div className={classes.userList}>
            <div className={classes.userInfo}>
              <UserInfo users={users} />
            </div>

            <div className={classes.users}>
              {users &&
                users
                  .filter(user => user.email.includes(filterValue))
                  .map(user => {
                    return <UserSummary user={user} key={user.email} />;
                  })}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.contentLoader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "users", orderBy: ["email"] }])
)(UserList);
