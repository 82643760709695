import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, Switch, Route } from "react-router-dom";
import Dashboard from "../../components/dashboard/Dashboard";
import SignIn from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import Settings from "../../components/settings/Settings";
import UserList from "../../components/users/UserList";
import UpdateTrackingDetails from "../../components/order/UpdateTrackingDetails";
import OrderDetails from "../../components/order/OrderDetails";
import IconButton from "@material-ui/core/IconButton";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import StoreIcon from "@material-ui/icons/Store";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import Inventory from "../inventory/Inventory";
import Accounts from "../accounts/Accounts";
import Expenses from "../expenses/Expenses";
//import Stock from "../stock/Stock";
import CreateProduct from "../inventory/CreateProduct";
import UpdateProduct from "../inventory/UpdateProduct";
import UpdatePaymentDetails from "../order/UpdatePaymentDetails";
import { checkIfAdmin } from "../../store/actions/authActions";
import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../../logo.png";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginleft: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

function PersistentDrawerLeft({ auth }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  checkIfAdmin();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return auth.uid ? (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="logo" height="30" width="180" />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          Admin Portal 2.1.1 Beta
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            component={Link}
            to="../"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="../users"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="../inventory"
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              <AddShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Shop" />
          </ListItem>
        </List>
        <Divider />
        <ListItem
          button
          component={Link}
          to="../settings"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="../accounts"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts" onClick={handleDrawerClose} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="../expenses"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Expenses" onClick={handleDrawerClose} />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="../stock"
          onClick={handleDrawerClose}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Stock" onClick={handleDrawerClose} />
        </ListItem>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/users" component={UserList} />
          <Route path="/preOrders" component={Dashboard} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/settings" component={Settings} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/expenses" component={Expenses} />
          {/*}  <Route path="/stock" component={Stock} /> */}
          <Route path="/createProduct" component={CreateProduct} />
          <Route path="/updateProduct" component={UpdateProduct} />
          <Route path="/order/:id" component={OrderDetails} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />

          <Route path="/tracking/:id" component={UpdateTrackingDetails} />
          <Route path="/payment/:id" component={UpdatePaymentDetails} />
        </Switch>
      </main>
    </div>
  ) : (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/users" component={SignIn} />
      <Route path="/preOrders" component={SignIn} />
      <Route path="/inventory" component={SignIn} />
      <Route path="/signin" component={SignIn} />
      <Route path="/settings" component={SignIn} />
      <Route path="/accounts" component={SignIn} />
      <Route path="/expenses" component={SignIn} />
      <Route path="/createProduct" component={SignIn} />
      <Route path="/updateProduct" component={UpdateProduct} />
    </Switch>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkIfAdmin: user => dispatch(checkIfAdmin(user))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PersistentDrawerLeft);
