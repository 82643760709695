import React, { useState } from "react";
import Checkout from "./Checkout";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CartItem from "./CartItem";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "10px",
    color: "#3b5998",
    width: "80%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto"
    }
  },

  overview: {
    fontSize: "30px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px"
    }
  },
  removeButton: {
    backgroundColor: "#3498db",
    width: "50%"
  },
  total: {
    fontSize: "30px",
    textAlign: "center",
    paddingTop: "10px"
  },
  backToShop: {
    textAlign: "center",
    padding: "10px"
  },
  checkout: {
    width: "100%",
    padding: "10px"
  }
}));

const NewCart = props => {
  const classes = useStyles();
  const [courierFee, setCourierFee] = useState(false);
  const [discount, setDiscount] = useState(false);

  function calcCourierFee(courier) {
    setCourierFee(75); // magic number should come from the db
  }

  function getDiscount(discount) {
    setDiscount(discount);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.overview}>Order Summary</Typography>
        {props.cartItems.length === 0 ? (
          <Typography> Cart is Empty</Typography>
        ) : null}
        <div id="cartItem">
          {props.cartItems.map(item => (
            <CartItem item={item} remove={props.remove} />
          ))}
        </div>
        {props.cartItems.length > 0 ? (
          <div id="cartInfo">
            <div id="total" className={classes.total}>
              Total : R
              {Number(
                props.cartItems.reduce((a, c) => a + c.price * c.count, 0)
              ) +
                Number(courierFee) -
                Number(discount)}
            </div>

            <Checkout
              calcCourierFee={calcCourierFee}
              getDiscount={getDiscount}
              cartItems={props.cartItems}
              totalCost={props.cartItems.reduce(
                (a, c) => a + c.price * c.count,
                0
              )}
              courierFee={courierFee}
            />
          </div>
        ) : null}

        <div className={classes.backToShop} onClick={props.hide}>
          Back to Shop{" "}
        </div>
      </CardContent>
    </Card>
  );
};

export default NewCart;
